import React, { useState, useEffect, useCallback } from "react";
import styled from "@emotion/styled";

// 卡在頂部就好
const TabBarItem = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.3);

  .tab_button{
    cursor: pointer;
    position: relative;
    width: 150px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 16px;
    font-weight: 900;
    transition: 0.1s;

    &.active{
      color: #ac6401;
    }

    &_active_bar{
      position: absolute;
      bottom: 0;
      width: 150px;
      height: 3px;
      background-color: #ac6401;
      transition: 0.1s;
    }

  }
`;



const TabBar = ({ currentTabIndex, setCurrentTabIndex, tabButton }) => {
  // 用來設定tab下方橫條的寬度
  const [tabWidth, setTabWidth] = useState(0);
  // 用來設定下方橫條要移動到哪裡
  const [tabBarMovePostion, setTabBarMovePostion] = useState(0);
  // 用來控制滑鼠滑動至哪個tab
  const [moveTabBar, setMoveTabBar] = useState(currentTabIndex);

  // 處理下方橫條大小和移動定位
  const resizeAndMoveTabBar = useCallback(() => {
    setTabWidth(document.getElementsByClassName('tab_button')[0]?.offsetWidth);
    setTabBarMovePostion(document.getElementsByClassName('tab_button')[moveTabBar]?.offsetLeft);
  }, [moveTabBar])

  window.addEventListener('resize', () => {
    resizeAndMoveTabBar();
  });

  useEffect(() => {
    resizeAndMoveTabBar();
  }, [resizeAndMoveTabBar]);

  // 用來放計時器
  let overDelay;

  return (
    <TabBarItem>
      {tabButton.map((value, index) => {
        return (
          <div
            className={"tab_button " + (currentTabIndex === index ? 'active' : '')}
            key={'tab_button_' + index}
            onClick={() => {
              setMoveTabBar(index);
              setCurrentTabIndex(index);
            }}
            onMouseEnter={() => {
              clearTimeout(overDelay);
              setMoveTabBar(index);
            }}
            onMouseLeave={() => {
              overDelay = setTimeout(() => {
                setMoveTabBar(currentTabIndex);
              }, 500);
            }}
          >
            {value}
          </div>
        )
      })}
      <div
        className="tab_button_active_bar"
        style={{
          width: tabWidth + 'px',
          left: tabBarMovePostion + 'px'
        }}
      >

      </div>
    </TabBarItem>
  )
}

export default TabBar;