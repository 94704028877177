//

import heightLightBarItemIcon1 from "./assets/images/product/product/heightLightBarItemIcon/icon1.png";
import heightLightBarItemIcon2 from "./assets/images/product/product/heightLightBarItemIcon/icon2.png";
import heightLightBarItemIcon3 from "./assets/images/product/product/heightLightBarItemIcon/icon3.png";
import heightLightBarItemIcon4 from "./assets/images/product/product/heightLightBarItemIcon/icon4.png";

//

import ad_slider1 from "./assets/images/product/product/sliderImg/slider1.gif";
import ad_slider2 from "./assets/images/product/product/sliderImg/slider2.gif";
import ad_slider3 from "./assets/images/product/product/sliderImg/slider3.gif";
import ad_slider4 from "./assets/images/product/product/sliderImg/slider4.gif";
import ad_slider5 from "./assets/images/product/product/sliderImg/slider5.gif";
// import ad_slider6 from "./assets/images/product/product/sliderImg/slider6.gif";
// import ad_slider7 from "./assets/images/product/product/sliderImg/slider7.gif";
// import ad_slider8 from "./assets/images/product/product/sliderImg/slider8.gif";
// import ad_slider9 from "./assets/images/product/product/sliderImg/slider9.gif";
// import ad_slider10 from "./assets/images/product/product/sliderImg/slider10.gif";

//

import ad_S1 from "./assets/images/product/product/midAds/ad_S1.png";
import ad_S2 from "./assets/images/product/product/midAds/ad_S2.png";

// productListImage

// import product_qudao from "./assets/images/product/qudao.gif";

import product_bliav from "./assets/images/product/bliav.png";
import product_Mimacomic from "./assets/images/product/迷妹漫画.png";
import product_PAPA from "./assets/images/product/product/listProduct/PAPA.png";
import product_dongi from "./assets/images/product/東京視頻.png";
import product_thisav from "./assets/images/product/ThisAV.png";
import product_Jabletv from "./assets/images/product/Jable.TV.png";
import product_CableAV from "./assets/images/product/CableAV.png";
import product_18AV from "./assets/images/product/18AV.jpg";
import product_calabeabanet from "./assets/images/product/加勒逼A片網.png";
import product_fc2 from "./assets/images/product/FC2影音.jpg";
import product_Avgle from "./assets/images/product/Avgle.png";
import product_asiaAV from "./assets/images/product/亞洲色情網.png";
import product_PornHub from "./assets/images/product/PornHub.jpg";
import product_xnxx from "./assets/images/product/xnxx.jpg";
import product_YouPorn from "./assets/images/product/YouPorn.jpg";
import product_xVideos from "./assets/images/product/xVideos.png";
import product_PornBest from "./assets/images/product/PornBest.png";

import product_chuniao from "./assets/images/product/product/listProduct/chuniao.png";
import product_91banana from "./assets/images/product/product/listProduct/91banana.jpg";
import product_meta from "./assets/images/product/product/listProduct/妹塔宇宙.png";

const heightLightBarItemArray = [
  {
    title: "B次元",
    image: heightLightBarItemIcon1,
    url: "https://bliav.com/downzai/",
  },
  {
    title: "泡泡直播",
    image: heightLightBarItemIcon2,
    url: "https://bliacg.com/bb/jy",
  },
  {
    title: "Lutube视频",
    image: heightLightBarItemIcon3,
    url: "https://bliacg.com/lutu2/icon",
  },
  {
    title: "雛鳥短視頻",
    image: heightLightBarItemIcon4,
    url: "https://cn08.me/?code=&channel=hh158640",
  },
];

const sliderPageArr = [
  {
    title: "Lutube视频",
    url: "https://bliacg.com/lutu2/jyd_hf",
    image: ad_slider1,
  },
  {
    title: "兵器少女",
    url: "https://l.tapdb.net/nMWyV5OP",
    image: ad_slider5,
  },
  {
    title: "泡泡直播",
    url: "https://bliacg.com/bb/jy",
    image: ad_slider3,
  },
  {
    title: "春香閣",
    url: "https://bliacg.com/yp",
    image: ad_slider2,
  },
  {
    title: "無限可能性的少女",
    url: "https://blid2.com/mgcm-sadjo28h3k1uhffased/dl",
    image: ad_slider4,
  },
];

const adsArray = [
  {
    text: "艳游记",
    image: ad_S1,
    url: "https://l.tapdb.net/T4sChGmK",
  },
  {
    text: "催眠异世界",
    image: ad_S2,
    url: "https://l.tapdb.net/prkhpWB3",
  },
  {
    text: "艳游记",
    image: ad_S1,
    url: "https://l.tapdb.net/T4sChGmK",
  },
  {
    text: "催眠异世界",
    image: ad_S2,
    url: "https://l.tapdb.net/prkhpWB3",
  },
];

const productListData = [
  {
    title: "妹塔AV",
    image: product_meta,
    size: "168.82m",
    version: "1.1.5",
    downloadTime: "3214568",
    url: "https://meitaav.com/?utm_source=jyd",
  },
  {
    title: "BB发车视频",
    image: product_bliav,
    size: "2047.82m",
    version: "3.1.5",
    downloadTime: "12345",
    url: "https://bliav.com/downzai/",
  },
  {
    title: "91香蕉",
    image: product_91banana,
    size: "66m",
    version: "1.1.5",
    downloadTime: "47897456",
    url: "https://a.chunyunhuijia.com/?_chan=jydxj0126",
  },
  {
    title: "雏鸟短視頻",
    image: product_chuniao,
    size: "324m",
    version: "1.0.5",
    downloadTime: "3256489",
    url: "https://cn08.me/?code=&channel=hh158640",
  },
  // {
  //   title: "御龙弑天(免费直充版)",
  //   image: product_qudao,
  //   size: "50m",
  //   version: "2.1.5",
  //   downloadTime: "59721357",
  //   url: "http://mtw.so/603ASi",
  // },
  {
    title: "谜妹漫画",
    image: product_Mimacomic,
    size: "2047.82m",
    version: "2.2.0",
    downloadTime: "1411145",
    url: "https://17mimei.club/rwwd0",
  },
  {
    title: "啪啪直播",
    image: product_PAPA,
    size: "372.82m",
    version: "1.2.0",
    downloadTime: "23657",
    url: "https://www.oej4z.vip/?channelId=QD721",
  },
  {
    title: "東京視頻",
    image: product_dongi,
    size: "357m",
    version: "3.1.5",
    downloadTime: "152660",
    url: "http://tokyomotion.net/",
  },
  {
    title: "ThisAV",
    image: product_thisav,
    size: "2047.82m",
    version: "2.2.0",
    downloadTime: "188956",
    url: "http://www.thisav.com/",
  },
  {
    title: "Jable.TV",
    image: product_Jabletv,
    size: "2047.82m",
    version: "2.2.0",
    downloadTime: "5800",
    url: "https://jable.tv/?qingse.one",
  },
  {
    title: "CableAV",
    image: product_CableAV,
    size: "2047.82m",
    version: "2.2.0",
    downloadTime: "1411145",
    url: "https://cableav.tv/?qingse.one",
  },
  {
    title: "18AV",
    image: product_18AV,
    size: "2047.82m",
    version: "2.2.0",
    downloadTime: "1411145",
    url: "http://18av.mm-cg.com/?qingse.one",
  },
  {
    title: "加勒逼A片網",
    image: product_calabeabanet,
    size: "2047.82m",
    version: "2.2.0",
    downloadTime: "188956",
    url: "http://www.159i.com/?qingse.one",
  },
  {
    title: "FC2影音",
    image: product_fc2,
    size: "2047.82m",
    version: "2.2.0",
    downloadTime: "5800",
    url: "http://video.fc2.com/",
  },
  {
    title: "Avgle",
    image: product_Avgle,
    size: "2047.82m",
    version: "2.2.0",
    downloadTime: "1411145",
    url: "https://avgle.com/",
  },
  {
    title: "亞洲色情網",
    image: product_asiaAV,
    size: "2047.82m",
    version: "2.2.0",
    downloadTime: "1411145",
    url: "http://asianpornmovies.com/",
  },
  {
    title: "PornHub",
    image: product_PornHub,
    size: "2047.82m",
    version: "2.2.0",
    downloadTime: "1411145",
    url: "https://www.pornhub.com/",
  },
  {
    title: "xnss",
    image: product_xnxx,
    size: "2047.82m",
    version: "2.2.0",
    downloadTime: "1411145",
    url: "https://www.xnxx.com/",
  },
  {
    title: "YouPorn",
    image: product_YouPorn,
    size: "2047.82m",
    version: "2.2.0",
    downloadTime: "1411145",
    url: "https://www.youporn.com/",
  },
  {
    title: "xVideos",
    image: product_xVideos,
    size: "2047.82m",
    version: "2.2.0",
    downloadTime: "1411145",
    url: "https://www.xvideos.com/",
  },
  {
    title: "PornBest 台灣素人",
    image: product_PornBest,
    size: "2047.82m",
    version: "2.2.0",
    downloadTime: "1411145",
    url: "http://www.pornbest.org/q/%E5%8F%B0%E7%81%A3",
  },
];

let data = {
  heightLightBarItemArray,
  sliderPageArr,
  productListData,
  adsArray,
};

export default data;
