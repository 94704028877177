//

import heightLightBarItemIcon1 from "./assets/images/product/game/heightLightBarItemIcon/icon1.png";
import heightLightBarItemIcon2 from "./assets/images/product/game/heightLightBarItemIcon/icon2.png";
import heightLightBarItemIcon3 from "./assets/images/product/game/heightLightBarItemIcon/icon3.png";
import heightLightBarItemIcon4 from "./assets/images/product/game/heightLightBarItemIcon/icon4.png";

//

import ad_slider1 from "./assets/images/product/game/sliderImg/slider1.gif";
import ad_slider2 from "./assets/images/product/game/sliderImg/slider2.gif";
import ad_slider3 from "./assets/images/product/game/sliderImg/slider3.gif";
import ad_slider4 from "./assets/images/product/game/sliderImg/slider4.gif";
import ad_slider5 from "./assets/images/product/game/sliderImg/slider5.gif";
// import ad_slider6 from "./assets/images/product/game/sliderImg/slider6.gif";
// import ad_slider7 from "./assets/images/product/game/sliderImg/slider7.gif";
// import ad_slider8 from "./assets/images/product/game/sliderImg/slider8.gif";
// import ad_slider9 from "./assets/images/product/game/sliderImg/slider9.gif";
// import ad_slider10 from "./assets/images/product/game/sliderImg/slider10.gif";

//

import ad_S1 from "./assets/images/product/game/midAds/ad_S1.png";
import ad_S2 from "./assets/images/product/game/midAds/ad_S2.png";

// productListImage

import product_01 from "./assets/images/product/game/listProduct/01.png";
import product_02 from "./assets/images/product/game/listProduct/02.png";
import product_03 from "./assets/images/product/game/listProduct/03.png";
import product_04 from "./assets/images/product/game/listProduct/04.png";
import product_05 from "./assets/images/product/game/listProduct/05.png";
import product_06 from "./assets/images/product/game/listProduct/06.png";
import product_07 from "./assets/images/product/game/listProduct/07.png";
import product_08 from "./assets/images/product/game/listProduct/08.png";
import product_09 from "./assets/images/product/game/listProduct/09.png";
import product_10 from "./assets/images/product/game/listProduct/10.png";
import product_11 from "./assets/images/product/game/listProduct/11.png";
import product_12 from "./assets/images/product/game/listProduct/12.png";
import product_13 from "./assets/images/product/game/listProduct/13.png";
import product_14 from "./assets/images/product/game/listProduct/14.png";
// import product_15 from "./assets/images/product/game/listProduct/15.png";
import product_16 from "./assets/images/product/game/listProduct/16.png";
import product_17 from "./assets/images/product/game/listProduct/17.png";
// import product_18 from "./assets/images/product/game/listProduct/18.png";
import product_19 from "./assets/images/product/game/listProduct/19.png";
import product_20 from "./assets/images/product/game/listProduct/20.png";


const heightLightBarItemArray = [
  {
    title: "B次元",
    image: heightLightBarItemIcon1,
    url: "https://bliav.com/downzai/",
  },
  {
    title: "泡泡直播",
    image: heightLightBarItemIcon2,
    url: "https://bliacg.com/bb/jy",
  },
  {
    title: "Lutube视频",
    image: heightLightBarItemIcon3,
    url: "https://bliacg.com/lutu2/icon",
  },
  {
    title: "雛鳥短視頻",
    image: heightLightBarItemIcon4,
    url: "https://cn08.me/?code=&channel=hh158640",
  },
];

const sliderPageArr = [
  {
    title: "Lutube视频",
    url: "https://bliacg.com/lutu2/jyd_hf",
    image: ad_slider1,
  },
  {
    title: "兵器少女",
    url: "https://l.tapdb.net/nMWyV5OP",
    image: ad_slider5,
  },
  {
    title: "泡泡直播",
    url: "https://bliacg.com/bb/jy",
    image: ad_slider3,
  },  
  {
    title: "春香閣",
    url: "https://bliacg.com/yp",
    image: ad_slider2,
  },
  {
    title: "無限可能性的少女",
    url: "https://blid2.com/mgcm-sadjo28h3k1uhffased/dl",
    image: ad_slider4,
  },
];
const adsArray = [
  {
    text: "艳游记",
    image: ad_S1,
    url: "https://l.tapdb.net/T4sChGmK",
  },
  {
    text: "催眠异世界",
    image: ad_S2,
    url: "https://l.tapdb.net/prkhpWB3",
  },
  {
    text: "艳游记",
    image: ad_S1,
    url: "https://l.tapdb.net/T4sChGmK",
  },
  {
    text: "催眠异世界",
    image: ad_S2,
    url: "https://l.tapdb.net/prkhpWB3",
  },
];

const productListData = [
  {
    title: "刀剑学园",
    image: product_01,
    size: "55m",
    version: "1.4.5",
    downloadTime: "7845312",
    url: " https://sao.onelink.me/PuYR/114",
  },
  // { // XX
  //   title: "爱伊莎",
  //   image: product_02,
  //   size: "55m",
  //   version: "1.5.5",
  //   downloadTime: "4561234",
  //   url: "https://ais.onelink.me/89SQ/114",
  // },
  {
    title: "吃姬",
    image: product_05,
    size: "658.3m",
    version: "2.3.5",
    downloadTime: "354127",
    url: "https://tdw.onelink.me/NECd/114",
  },
  {
    title: "无尽之夜",
    image: product_04,
    size: "452m",
    version: "1.1.7",
    downloadTime: "4538712",
    url: "https://kis.onelink.me/Xuch/114",
  },
  {
    title: "后宫秘密",
    image: product_13,
    size: "123m",
    version: "1.4.6",
    downloadTime: "34534343",
    url: "https://qsc.onelink.me/EKaH/114",
  },
  // { // XX
  //   title: "女神连结",
  //   image: product_13,
  //   size: "123m",
  //   version: "1.4.6",
  //   downloadTime: "34534343",
  //   url: "https://gco.onelink.me/wNKY/114",
  // },
  {
    title: "艳游记",
    image: product_02,
    size: "55m",
    version: "1.5.5",
    downloadTime: "4561234",
    url: "https://jtw.onelink.me/G4I7/114",
  },
  {
    title: "SF性斗士",
    image: product_08,
    size: "456.3m",
    version: "1.6.5",
    downloadTime: "9453217",
    url: "https://sfgzh.onelink.me/Zzge/114",
  },
  {
    title: "腥成",
    image: product_06,
    size: "75.6m",
    version: "2.9.5",
    downloadTime: "35487123.",
    url: "https://kow1.onelink.me/Vy0I/114",
  },
  {
    title: "太空幻想",
    image: product_16,
    size: "7895m",
    version: "2.1.1",
    downloadTime: "64512357",
    url: "https://spfzh.onelink.me/4lr2/114",
  },
  {
    title: "催眠异世界",
    image: product_03,
    size: "854m",
    version: "1.5.5",
    downloadTime: "45345312",
    url: "https://haw.onelink.me/yT2r/114",
  },
  {
    title: "中出世纪",
    image: product_09,
    size: "35m",
    version: "2.1.0",
    downloadTime: "4538712",
    url: "https://114.onelink.me/dDfw/114",
  },
  {
    title: "学院调教日常",
    image: product_11,
    size: "423m",
    version: "6.5.5",
    downloadTime: "35478645",
    url: "https://ipzh.onelink.me/d1v3/114",
  },
  {
    title: "我的超能干学妹",
    image: product_10,
    size: "586m",
    version: "2.4.5",
    downloadTime: "5676123",
    url: "https://qtzh.onelink.me/w83Z/114",
  },
  {
    title: "命运女神指定",
    image: product_19,
    size: "22m",
    version: "2.1.5",
    downloadTime: "45678456",
    url: "https://fgo.onelink.me/Fbhi/114",
  },
  {
    title: "超级机娘大战",
    image: product_12,
    size: "33m",
    version: "4.1.5",
    downloadTime: "5467123",
    url: "https://gdw.onelink.me/WiZE/114",
  },
  {
    title: "春宫",
    image: product_07,
    size: "33.6m",
    version: "2.8.5",
    downloadTime: "59721357",
    url: "https://miw.onelink.me/IZ6Y/114",
  },
  {
    title: "千姬三国",
    image: product_20,
    size: "56.3m",
    version: "1.1.5",
    downloadTime: "9784512",
    url: "https://gsgzh.onelink.me/mYFT/114",
  },
  {
    title: "雀姬的异想世界",
    image: product_17,
    size: "7895m",
    version: "2.0.5",
    downloadTime: "123783123",
    url: "https://hmj.onelink.me/FuZ9/114",
  },
  {
    title: "我的乳摇学妹",
    image: product_14,
    size: "890m",
    version: "1.7.5",
    downloadTime: "12389645",
    url: "https://stszh.onelink.me/1ghE/114",
  },

  // {
  //   title: "名妓三国",
  //   image: product_15,
  //   size: "8952m",
  //   version: "1.6.5",
  //   downloadTime: "65461237",
  //   url: "https://l.tyrantdb.com/uMtJ9wBR",
  // },
  // {
  //   title: "深海禁欲",
  //   image: product_18,
  //   size: "895m",
  //   version: "3.1.5",
  //   downloadTime: "6879456123",
  //   url: "https://l.tyrantdb.com/42sHOTXq",
  // },
];

let data = {
  heightLightBarItemArray,
  sliderPageArr,
  productListData,
  adsArray,
};

export default data;
