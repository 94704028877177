import React, { useRef, useState } from "react";
import styled from "@emotion/styled";
import { CSSTransition } from "react-transition-group";

import QRCode from "qrcode";

import jingyinisland from "../assets/images/jingyinisland.png";
import qrcodeBg from "../assets/images/qrcode-bg.png";


const PopUpItem = styled.div`
  z-index: 20;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000aa;
  .container {
    box-sizing: border-box;
    padding: 40px;
    padding-bottom: 250px;
    position: relative;
    width: 95%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    max-width: 360px;
    max-height: 100%;
    background-image: url(${qrcodeBg});
    background-size: cover;
    @media (max-width: 767px) {
      width: 100%;
      max-width: unset;
      height: 100%;
      padding-bottom: unset;
    }

    &_logo {
      text-align: center;
      &_img{
        vertical-align: middle;
        width: 70vw;
        max-width: 260px;
      }
    }



    &_text {
      color: #fff;
      filter: drop-shadow(0px 0px 2px black);
      font-weight: 900;
      margin-top: 10px;
      &_span{
        display: block;
        text-align: center;
        font-size: 24px;
        line-height: 1.6em;
        letter-spacing: 2px;
      }
    }

    &_qrcode {
      width: 60%;
      margin-top: 10px;
      img {
        width: 100%;
        vertical-align: middle;
      }
    }

    .close {
      cursor: pointer;
      position: absolute;
      top: 10px;
      right: 10px;
      width: 40px;
      height: 40px;
      background-color: #000000aa;
      border-radius: 50%;
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 85%;
        height: 5px;
        background-color: #fff;
      }

      &::before {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
`;

const PopQrcode = ({ qrcodeShow, setQrcodeShow }) => {
  const popItem = useRef(null);

  const [url, setUrl] = useState(null);

  QRCode.toDataURL(window.location.href, {
    margin: 1
  })
    .then((url) => {
      setUrl(url);
    })
    .catch((err) => {
      console.error(err);
    });

  return (
    <CSSTransition
      in={qrcodeShow}
      nodeRef={popItem}
      timeout={300}
      classNames="qrcode_transform"
      unmountOnExit
      onEnter={() => setQrcodeShow(true)}
      onExited={() => setQrcodeShow(false)}
    >
      <PopUpItem
        ref={popItem}
        onClick={() => {
          setQrcodeShow(false);
        }}
      >
        <div
          className="container"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            className="close"
            onClick={() => {
              setQrcodeShow(false);
            }}
          />
          <div className="container_logo">
            <img
              className="container_logo_img"
              src={jingyinisland}
              alt="精淫島"
            />
          </div>
          <div className="container_text">
            <span className="container_text_span">
              赶快截图收藏永久链接
            </span>
            <span className="container_text_span">
              才不会找不到我们
            </span>
          </div>
          <div className="container_qrcode">
            <img src={url} alt={"qrcode"} />
          </div>
        </div>
      </PopUpItem>
    </CSSTransition>
  );
};

export default PopQrcode;
