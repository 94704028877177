import React from "react";
import styled from "@emotion/styled";

const ProductItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 13px 0px;
  border-bottom: 1px solid #efefef;
  font-size: 1vw;
  &:last-child{
    border-bottom: none;
  }
  .product_item{
    &_imgbox{
      flex-shrink: 0;
      width: 20%;
      overflow: hidden;
      border-radius: 10px;
      &_img{
        width: 100%;
        vertical-align: middle;
      }
    }
    &_containerbox{
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin: 0px 10px;
      &_titlebox{
        &_title{
          font-size: 4.2em;
        }
      }
      &_descriptionbox{
        color: #979797;
        font-size: 3em;
        display: flex;
        justify-content: space-between;
        padding-right: 5vw;
      }
    }
    &_buttonbox{
      flex-shrink: 0;
      display: flex;
      align-items: center;
      &_button{
        box-sizing: border-box;
        font-size: 4em;
        padding: 3vw 6vw;
        border-radius: 12px;
        box-shadow: 0px 2px 6px 0 rgba(0, 0, 0, 0.6);
        background-color: #ffffff;
        color: #111;
        text-decoration: none;
      }
    }
  }
  @media (min-width: 750px){
    font-size: 6px; 
    .product_item{
      &_buttonbox{
        &_button{
          padding: 20px 40px;
        }
      }
    }
  }
`;

const ProductList = ({productListData}) => {
  return (
      <ProductItem
        className="product_item"
      >  
        <div
          className="product_item_imgbox"
        >
          <img 
            className="product_item_imgbox_img" 
            src={productListData.image} 
            alt={productListData.title}
          />
        </div>
        <div className="product_item_containerbox">
          <div className="product_item_containerbox_titlebox">
            <h3 className="product_item_containerbox_titlebox_title">{productListData.title}</h3>
          </div>
          <div className="product_item_containerbox_descriptionbox">
            <p className="product_item_containerbox_descriptionbox_size">大小 : {productListData.size}</p>
          </div>
          <div className="product_item_containerbox_descriptionbox">
            <p className="product_item_containerbox_descriptionbox_version">版本 : {productListData.version}</p>
            <p className="product_item_containerbox_descriptionbox_time">{productListData.downloadTime}次 下载</p>
          </div>
        </div>
        <div
          className="product_item_buttonbox"
        >
          <a 
            className="product_item_buttonbox_button"
            href={productListData.url}
            target="_blank"
            rel="noreferrer noopener"
          >
            下载
          </a>
        </div>
      </ProductItem>
  )
}

export default ProductList;