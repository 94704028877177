import React from "react";
import styled from "@emotion/styled";

const ConnectItem = styled.div`
  border-top: 1px solid #c3c3c3;
  padding: 10px 0;
  .connect_item{
    box-sizing: border-box;
    width: 95%;
    padding: 0 1vw;
    margin: auto;
    &_title{
      font-size: 30px;
      position: relative;
      padding-left: 10px;
      &::before{
        content: '';
        position: absolute;
        left: 0px;
        top: 0px;
        bottom: 0px;
        width: 5px;
        background-color: #ffd900;
      }
    }
    &_linkbox{
      padding: 10px 0;
    }
  }
  @media (min-width : 750px) {
    .connect_item{
      width: 100%;
      padding: 20px 30px;
    }
  }
`;

const ConnectItemLinkboxItem = styled.div`
  display: inline-block;
  padding: 1px 9px 1px 1px;
  line-height: 1.4em;
  letter-spacing: 0.2px;
  .connect_item_linkbox_item_url{
    color: #666666;
    text-decoration: none;
    &:hover{
      color: #fc5c53;
    }
  }
`;

const Connect = ({linkList}) =>{
  return (
    <ConnectItem>
      <div className="connect_item">
        <h6 className="connect_item_title">友站链接</h6>
        <div className="connect_item_linkbox">
          {linkList.map((value, index)=>{
            return (
              <ConnectItemLinkboxItem
              key={'ConnectItemLinkboxItem_' + index}
              >
                <a 
                  className="connect_item_linkbox_item_url" 
                  href={value.url}
                  target="_blank"
                  rel="noreferrer noopener"
                >{value.title}</a>
              </ConnectItemLinkboxItem>
            )
          })}
        </div>
      </div>
    </ConnectItem>
  )
}

export default Connect;