import React from "react";
import styled from "@emotion/styled";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faBullhorn, 
  faUsers, 
  faAd, 
  faCalendarAlt,
  faComments,
  faHandshake
} from '@fortawesome/free-solid-svg-icons';

import jingyinisland from "../assets/images/logowithd.png";
import PCbgLeft from "../assets/images/PCbg01.png";
import PCbgRight from "../assets/images/PCbg02.png";


const RootBody = styled.div`
  z-index: 30;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background: #fff;
  @media (min-width: 1200px) {
    background: no-repeat right bottom / contain url(${PCbgRight}),
      no-repeat left bottom / contain url(${PCbgLeft}),
      #fff;
    background-attachment: fixed;
  }

  .bg-c-CW {
    background-color: #fffbe3;
  }

  .bg-c-w {
    background-color: #fff;
  }

  .bg-i-lg-y {
    background-image: linear-gradient( to top, rgba(255, 217, 0, 1), rgba(255, 226, 0, 1), #fef100 );
  }
`;

const RootWidth = styled.section`
  max-width: 750px;
  margin: auto;
  padding-bottom: 20px;
  background-image: linear-gradient(
    to right,
    #ffffff00 0%,
    #ffffff 20%,
    #ffffff 80%,
    #ffffff00 100%
  );
`;

const CoverInfoTitle = styled.div`
padding: 10px 0;
.container {
  @media (min-width : 768px) {
    display: flex;
  }
  &_logo , &_description {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &_logo {
    padding-right: 10px;
    &_logo {
      width: 100%;
      &_img {
        width: 100%;
        vertical-align: middle;
      }
    }
    &_address {
      display: flex;
      justify-content: space-between;
      color: #fef100;
      border-bottom: 3px solid #fef100;
      &_text {
        filter: drop-shadow(0px 0px 2px #000);
        vertical-align: bottom;
        font-size: 45px; 
        font-weight: 900;
      }
    }
  }
  &_description {
    padding-left: 10px;
    &_recommend , &_collect {
      display: flex;
      justify-content: space-between;
    }
    &_recommend {
      box-sizing: border-box;
      padding: 35px 5px;
      font-size: 30px; 
      font-style: italic;
      border-top: 5px solid #fef100;
      border-bottom: 5px solid #fef100;
      &_text {
        font-weight: 900; 
        transform: scaleY(1.6);
      }
    }
    &_collect {
      padding-bottom: 4px;
      border-bottom: 2px solid #fef100;
      &_text {
        font-weight: 900;
        font-size: 26px;
      }
    }
  }
  @media (max-width : 768px) {
    padding: 0 10px;
    box-sizing: border-box;
    &_logo , &_description {
      padding: 0;
      width: 100%;
    }
    &_logo {
      &_address {
        &_text {
          font-size: 30px;
        }
      }
    }
    &_description {
      &_recommend {
        padding: 10px 0;
        &_text {
          font-size: 16px;
          transform: scaleY(1.2);
        }
      }
      &_collect {
        padding: 10px 0;
        &_text {
          font-size: 12px;
        }
      }
    }
  }
}
`;

const CoverInfoNav = styled.div`
padding-top: 10px;
  .container{
    display: flex;
    &_button {
      display: inline-block;
      cursor: pointer;
      text-align: center;
      flex-grow: 1;
      padding: 15px 0;
      margin: 1px;
      color: #000;
      text-decoration: none;
      font-size: 20px;
      background-image: linear-gradient( to top, rgba(255, 217, 0, 1), rgba(255, 226, 0, 1), #fef100 );
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

const CoverInfoNotify = styled.div`
padding-top: 10px;
  .container{
    &_description {
      padding: 10px;
      &_notify {
        font-size: 14px;
        &_iconbox {
          display: inline-block;
          padding: 2px 8px;
          margin-right: 5px;
          border-radius: 2px;
          &_icon {

          }
        }
        &_text {
          letter-spacing: 1px;
          line-height: 1.4em;
        }
      }
    }
  }
`;

const CoverCardElement = styled.div`
padding-top: 10px;
.container {

  &_card {
    &_header, &_body {
      padding: 16px;
    }
    &_header {
      font-size: 30px;
      &_icon, &_title {
        display: inline-block;
      }
      &_icon {
        margin-right: 10px;
        &_icon {
        }
      }
      &_title {

      }
    }

    &_body {
      &_description{

        p, li {
          margin: 1em 0;
          &:first-child {
            margin-top: 0;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
`;




const CoverInfo = () => {

  const jingyindaoUrl = 'Jingyindao.com'.split('');
  const recommendStr = '最權威的中文福利導航'.split('');
  const collectStr = '強烈建議你 ctrl + D 將本站收藏'.split('');

  const containerData = [
    {
      id: 'aboutUs',
      titleIcon: faUsers,
      title: '关于我们',
      element: ()=>(<>
                    <p>精淫岛创建于2021年</p>
                    
                    <p>这里所分享的内容都经过我们的精心挑选</p>
                    
                    <p>我们希望能满足您的所有需要</p>
                    
                    <p>所以不断整合网路上分散的福利网站</p>
                    
                    <p>只为让您获得最简单、最全面、最整洁的LSP网址导航体验</p>
                  </>),
    },
    {
      id: 'adConnect',
      titleIcon: faAd,
      title: '广告合作',
      element: ()=>(<>
                  <ol>
                    <li>1、有意在本站刊登广告，请将您的产品详情发送至客服邮箱;</li>
                    <li>2、广告不得影响用户体验，不接受悬浮、弹窗、病毒类广告;</li>
                    <li>3、大客户尊享荐 APP图标及字体颜色定制；</li>
                    <li>4、广告仅支持  USDT 或者  BTC结算；</li>
                    <li>5、广告到期本站将默认提醒，未及时续费本站有权另行处理！</li>
                  </ol>
      </>),
    },
    
    {
      id: 'teamwork',
      titleIcon: faHandshake,
      title: '友链合作',
      element: ()=>(<>
                  <p>以下内容务必详细阅读，否则不会收录！</p>
                  <ol>

                    <li>1、请先设置本站地址:  https://jingyindao.com/为友情链接;</li>
                    <li>2、将福利百科放置于全站友链首位，二三位选择性收录，其他位置不予收录;</li>
                    <li>3、将站点资料发送至福利百科官方邮箱;</li>
                    <li>4、不收录站群类站点/恶意代码站点/纯付费站点;</li>
                    <li>5、本站地址较多，您的统计来路可能不准确！</li>
                  </ol>
                  <p>什么样的网站会被取消收录？</p>
                  <ol>
                    <li>1、无故下链、随意变更友链位置;</li>
                    <li>2、连续一周流量来路过低;</li>
                    <li>3、使用站群技术骗取流量，后台核实为虚假流量；</li>
                    <li>4、网站经管理员巡查发现无法正常访问；</li>
                    <li>5、经用户举报包含假冒本站/恶意代码/虚假信息的站点。</li>
                  </ol>
      </>),
    },
    {
      id: 'contactUs',
      titleIcon: faComments,
      title: '联络我们',
      element: ()=>(<>
                  <p>关于友情链接及广告刊登的相关事宜，请联系电子邮箱： jingyindao@gmail.com</p>
                  <p>如果有一天网站忽然消失，你又想找到我们...请发送任意内容到上述邮箱找到回家的路</p>
      </>),
    },
    {
      id: 'updateCalend',
      titleIcon: faCalendarAlt,
      title: '更新日志',
      element: ()=>(<>
                  <ol>
                    <li>2021：网站上线</li>
                  </ol>
      </>),
    },
  ]

  return (
    <RootBody>
      <RootWidth>

        <CoverInfoTitle>
          <div className="container">
            <div className="container_logo">
              <div className="container_logo_logo">
                <img
                  className="container_logo_logo_img"
                  src={jingyinisland}
                  alt="精淫島"
                />
              </div>
              <div className="container_logo_address">
                {jingyindaoUrl.map((value, index)=>{
                  return (
                    <span 
                      className="container_logo_address_text"
                      key={value + '-' + index}
                    >{value}</span>
                  )
                })}
              </div>
            </div>
            <div className="container_description">
              <div className="container_description_recommend">
                {recommendStr.map((value, index) => {
                  return (
                    <span 
                      className="container_description_recommend_text"
                      key={value + '-' + index}
                    >
                      {value}
                    </span>
                  )
                })}
              </div>
              <div className="container_description_collect">
                {collectStr.map((value, index) => {
                  return (
                    <span 
                      className="container_description_collect_text"
                      key={value + '-' + index}
                    >
                      {value}
                    </span>
                  )
                })}
              </div>
            </div>
          </div>
        </CoverInfoTitle>

        <CoverInfoNav>
          <div className="container">
            <a
              className="container_button"
              href="/"
              target="_blank"
            >首页</a>
            <a 
              className="container_button"
              href="#adConnect"
            >合作</a>
            <a 
              className="container_button"
              href="#contactUs"
            >联络</a>
            <a 
              className="container_button"
              href="#updateCalend"
            >更新</a>
            {/* <a 
              className="container_button"
              href="#"
            >地址发布</a> */}
          </div>
        </CoverInfoNav>

        <CoverInfoNotify>
          <div className="container bg-c-CW">
            <div className="container_description">
              <p className="container_description_notify">
                <span className="container_description_notify_iconbox bg-i-lg-y">
                  <FontAwesomeIcon 
                    icon={faBullhorn}
                    className="container_description_notify_iconbox_icon"
                  />
                    友情提示
                </span>
                <span className="container_description_notify_text">
                  歡迎每個人的加入
                </span>
              </p>
            </div>
          </div>
        </CoverInfoNotify>


        { containerData.map((value)=> {
          return (
            <CoverCardElement
              key={value.title}
            >
              <div className="container">
                <div className="container_card">
                  <div
                    id={value.id}
                    className="container_card_header bg-i-lg-y"
                  >
                    <div className="container_card_header_icon">
                      <FontAwesomeIcon 
                        icon={value.titleIcon}
                        className="container_card_header_icon_icon"
                      />
                    </div>
                    <div className="container_card_header_title">
                      <p className="container_card_header_title_text">
                        {value.title}
                      </p>
                    </div>
                  </div>
                  <div className="container_card_body bg-c-w">
                    <div className="container_card_body_description">
                      <value.element/>
                    </div>
                  </div>
                </div>
              </div>
            </CoverCardElement>
          )
        })}

      </RootWidth>
    </RootBody>
  )
}

export default CoverInfo;