// import logo from './logo.svg';
import React, { useState, useRef, useEffect } from "react";
import { CSSTransition } from "react-transition-group";

import PCbgLeft from "./assets/images/PCbg01.png";
import PCbgRight from "./assets/images/PCbg02.png";
import jingyinisland from "./assets/images/jingyinisland.png";

import styled from "@emotion/styled";

import { linkList } from "./data";

import dataProduct from "./dataProduct";
import dataGame from "./dataGame";
import dataAnimat from "./dataAnimat";
import dataApp from "./dataApp";
import dataStream from "./dataStream";

import TabBar from "./components/TabBar";
import HeightLightBar from "./components/HeightLightBar";
import SliderSection from "./components/SliderSection";
import ProductList from "./components/ProductList";
import MiddleAds from "./components/MiddleAds";
import Connect from "./components/Connect";
import Footer from "./components/Footer";
import PopUpAds from "./components/PopUpAds";
import PopQrcode from "./components/PopQrcode";
import CoverInfo from "./components/CoverInfo";

// productListImage

import product_bliav from "./assets/images/product/bliav.png";
import product_muliLive from "./assets/images/product/muliLive.png";
import product_myGirl from "./assets/images/product/myGirl.png";
import product_sameCity from "./assets/images/product/sameCity.png";
import product_sexAngel from "./assets/images/product/sexAngel.png";
import product_sexBlog from "./assets/images/product/sexBlog.png";

// 資料區域

const tabButton = ["热门色站", "直播", "游戏", "动漫", "应用"];

// 假裝拿到資料後推了一個廣告欄位

addEmpty(dataProduct.productListData);
addEmpty(dataGame.productListData);
addEmpty(dataAnimat.productListData);
addEmpty(dataApp.productListData);
addEmpty(dataStream.productListData);

function addEmpty(array) {
  if (array.length <= 10) {
    array.splice(Math.floor(array.length / 2), 0, {});
  } else {
    let j = 0;
    for (let i = 5; i < array.length; i += 5) {
      if (array.length > i + j) {
        array.splice(i + j++, 0, {});
      }
    }
  }
}

// 放置資料區域

const RootBody = styled.div`
  overflow: hidden;
  .displaynone {
    display: none;
  }
  @media (min-width: 1200px) {
    background: no-repeat right bottom / contain url(${PCbgRight}),
      no-repeat left bottom / contain url(${PCbgLeft});
    background-attachment: fixed;
  }
  .float_bottom_ads {
    z-index: 1;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    padding: 10px;
    @media (min-width: 768px) {
      padding: 20px;
    }
    background-image: linear-gradient(
      to top,
      rgba(255, 217, 0, 0.85),
      rgba(255, 226, 0, 0.85),
      #fef100
    );
    &_container {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (min-width: 768px) {
        flex-direction: row;
      }
      &_img {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        @media (min-width: 768px) {
          padding-right: 20px;
        }
        img {
          width: 30vw;
          max-width: 150px;
          vertical-align: middle;
        }
      }
      &_p {
        text-align: center;
        letter-spacing: 1px;
        line-height: 1.4em;
      }
    }
    &_close {
      cursor: pointer;
      position: absolute;
      top: 10px;
      right: 10px;
      width: 40px;
      height: 40px;
      background-color: #000000aa;
      border-radius: 50%;
      @media (min-width: 768px) {
        top: 50%;
        transform: translateY(-50%);
      }
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 85%;
        height: 5px;
        background-color: #fff;
      }

      &::before {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
`;
// 卡在頂部就好
const HeaderBar = styled.header`
  height: 80px;
  background-image: linear-gradient(
    to top,
    rgba(255, 217, 0, 1),
    rgba(255, 226, 0, 1),
    #fef100
  );
  text-align: center;
  .header_bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 750px;
    height: 100%;
    margin: auto;
    &_logo {
      display: inline-block;
      vertical-align: middle;
      margin-left: 20px;
    }
    &_buttonbox {
      display: flex;
      padding-right: 10px;
      &_button {
        padding-right: 10px;
        &_p {
          display: block;
          color: #000;
          text-decoration: none;
          font-size: 12px;
          cursor: pointer;
          padding: 10px 20px;
          border: 1px solid #000;
          border-radius: 30px;
          transition: 0.3s;
          &:hover {
            color: #fff;
            border-color: #fff;
            background-color: #00000099;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    height: 55px;
    .header_bar {
      &_logo {
        width: 160px;
        &_img {
          width: 100%;
        }
      }
      &_buttonbox {
        padding-right: 5px;
        &_button {
          padding-right: 5px;
          &_p {
            display: block;
            color: #000;
            text-decoration: none;
            font-size: 12px;
            cursor: pointer;
            padding: 5px 10px;
            border: 1px solid #000;
            border-radius: 30px;
            transition: 0.3s;
            &:hover {
              color: #fff;
              border-color: #fff;
              background-color: #00000099;
            }
          }
        }
      }
    }
  }
`;

const MoreButton = styled.div`
  text-align: center;
  font-size: 1vw;
  padding-top: 10px;
  padding-bottom: 30px;
  .more_button {
    outline: none;
    cursor: pointer;
    box-sizing: border-box;
    font-size: 4em;
    font-weight: 900;
    padding: 10px 40px;
    border-radius: 12px;
    box-shadow: 0px 2px 6px 0 rgba(0, 0, 0, 0.6);
    background-color: #ffffff;
    color: #111;
    text-decoration: none;
    border: none;
  }
  @media (min-width: 750px) {
    font-size: 6px;
  }
`;

const SectionItem = styled.section`
  max-width: 750px;
  margin: auto;
  background-image: linear-gradient(
    to right,
    #ffffff00 0%,
    #ffffff 20%,
    #ffffff 80%,
    #ffffff00 100%
  );
  // 加分隔線用的
  &.product_list + .product_list {
    .product_item {
      border-top: 1px solid #00000011;
    }
  }
`;

const ContainerItem = styled.div`
  box-sizing: border-box;
  width: ${({ isFull }) => {
    return isFull ? "100%" : "95%";
  }};
  padding: ${({ isFull }) => {
    return isFull ? "0" : "0 1vw";
  }};
  margin: auto;
  @media (min-width: 750px) {
    padding: ${({ isFull }) => {
      return isFull ? "0" : "0 30px";
    }};
  }
`;

let cssTabIndex = window.localStorage.getItem('selectTab') ? window.localStorage.getItem('selectTab') : 0;

function App() {
  const productNode = useRef(null);
  const gameProductNode = useRef(null);
  const animalProductNode = useRef(null);
  const appProductNode = useRef(null);
  const streamProductNode = useRef(null);

  const [currentTabIndex, setCurrentTabIndex] = useState(parseInt(cssTabIndex));

  const [qrcodeShow, setQrcodeShow] = useState(false);
  const [connectShow, setConnectShow] = useState(false);

  const [currentData, setCurrentData] = useState([
    {
      ref: productNode,
      hightLightBar: dataProduct.heightLightBarItemArray,
      data: dataProduct.productListData,
      sliderData: dataProduct.sliderPageArr,
      ads: dataProduct.adsArray,
    },
    {
      ref: streamProductNode,
      hightLightBar: dataStream.heightLightBarItemArray,
      data: dataStream.productListData,
      sliderData: dataStream.sliderPageArr,
      ads: dataStream.adsArray,
    },
    {
      ref: gameProductNode,
      hightLightBar: dataGame.heightLightBarItemArray,
      data: dataGame.productListData,
      sliderData: dataGame.sliderPageArr,
      ads: dataGame.adsArray,
    },
    {
      ref: animalProductNode,
      hightLightBar: dataAnimat.heightLightBarItemArray,
      data: dataAnimat.productListData,
      sliderData: dataAnimat.sliderPageArr,
      ads: dataAnimat.adsArray,
    },
    {
      ref: appProductNode,
      hightLightBar: dataApp.heightLightBarItemArray,
      data: dataApp.productListData,
      sliderData: dataApp.sliderPageArr,
      ads: dataApp.adsArray,
    }
  ]);

  const addMoreList = function () {
    currentData[currentTabIndex].data = [
      ...currentData[currentTabIndex].data,
      {},
      {
        title: "新增次元",
        image: product_bliav,
        size: "2047.82m",
        version: "3.1.5",
        downloadTime: "12345",
        url: "#",
      },
      {
        title: "MuliLive",
        image: product_muliLive,
        size: "357m",
        version: "3.1.5",
        downloadTime: "152660",
        url: "#",
      },
      {
        title: "我的超能干学妹",
        image: product_myGirl,
        size: "2047.82m",
        version: "2.2.0",
        downloadTime: "188956",
        url: "#",
      },
      {
        title: "同城约炮",
        image: product_sameCity,
        size: "2047.82m",
        version: "2.2.0",
        downloadTime: "5800",
        url: "#",
      },
      {
        title: "性爱天使与变态魔族的性奴调教",
        image: product_sexAngel,
        size: "2047.82m",
        version: "2.2.0",
        downloadTime: "1411145",
        url: "#",
      },
      {
        title: "性博客",
        image: product_sexBlog,
        size: "2047.82m",
        version: "2.2.0",
        downloadTime: "1411145",
        url: "#",
      },
      {
        title: "我的超能干学妹",
        image: product_myGirl,
        size: "2047.82m",
        version: "2.2.0",
        downloadTime: "188956",
        url: "#",
      },
      {
        title: "同城约炮",
        image: product_sameCity,
        size: "2047.82m",
        version: "2.2.0",
        downloadTime: "5800",
        url: "#",
      },
      {
        title: "性爱天使与变态魔族的性奴调教",
        image: product_sexAngel,
        size: "2047.82m",
        version: "2.2.0",
        downloadTime: "1411145",
        url: "#",
      },
      {
        title: "性博客",
        image: product_sexBlog,
        size: "2047.82m",
        version: "2.2.0",
        downloadTime: "1411145",
        url: "#",
      },
    ];
    setCurrentData([...currentData]);
  };

  useEffect(() => {
    const webString = window.location.search;
    if (webString.indexOf("websiteOffer=true") !== -1) {
      setConnectShow(true);
    }

    // setTimeout(() => {
    //   let script = document.createElement('script');
    //   script.src = 'http://www.websiteasteroids.com/asteroids.min.js';
    //   document.getElementsByTagName('body')[0].appendChild(script);
    //   setInterval(() => {
    //     let div = document.createElement('div');
    //     div.innerHTML = 'fhasfdskjjkfhjk';
    //     document.getElementsByTagName('body')[0].appendChild(div);
    //   }, 1000);
    // }, 5000);
  }, []);

  return (
    <RootBody>
      <h1 className="displaynone">精淫岛</h1>
      <PopUpAds />
      <PopQrcode qrcodeShow={qrcodeShow} setQrcodeShow={setQrcodeShow} />
      {connectShow ? <CoverInfo setConnectShow={setConnectShow} /> : null}
      <HeaderBar>
        <nav className="header_bar">
          <h2 className="displaynone">精淫岛</h2>
          <div className="header_bar_logo">
            <img
              className="header_bar_logo_img"
              src={jingyinisland}
              alt="精淫岛"
            />
          </div>
          <div className="header_bar_buttonbox">
            <div className="header_bar_buttonbox_button">
              <a
                className="header_bar_buttonbox_button_p"
                href="/?websiteOffer=true"
                target="_blank"
              >
                網站提交
              </a>
            </div>
            <div
              className="header_bar_buttonbox_button"
              onClick={() => {
                setQrcodeShow(true);
              }}
            >
              <p className="header_bar_buttonbox_button_p">收藏我们</p>
            </div>
          </div>
        </nav>
      </HeaderBar>
      <SectionItem>
        <h2 className="displaynone">色 资讯 激情 老司机 发车</h2>
        <ContainerItem isFull={true}>
          <TabBar
            currentTabIndex={currentTabIndex}
            setCurrentTabIndex={(value) => {
              cssTabIndex = value;
              window.localStorage.setItem('selectTab', value);
              setCurrentTabIndex(value);
            }}
            tabButton={tabButton}
          />
        </ContainerItem>
      </SectionItem>
      <div className="container_transform_root">
        {currentData.map((listDate, index) => {
          return (
            <CSSTransition
              key={index}
              in={currentTabIndex === index}
              nodeRef={listDate.ref}
              timeout={300}
              classNames="container_transform"
              unmountOnExit
              onEnter={() => setCurrentTabIndex(index)}
              onExited={() => setCurrentTabIndex(cssTabIndex)}
            >
              <div ref={listDate.ref}>
                <SectionItem>
                  <ContainerItem>
                    <HeightLightBar
                      heightLightBarItemArray={listDate.hightLightBar}
                    />
                  </ContainerItem>
                </SectionItem>
                <SectionItem>
                  <ContainerItem isFull={true}>
                    <SliderSection
                      sliderPageArr={listDate.sliderData}
                    ></SliderSection>
                  </ContainerItem>
                </SectionItem>
                {listDate.data.map((value, index) => {
                  return value.title ? (
                    <SectionItem
                      className="product_list"
                      key={"product_list_item_" + index}
                    >
                      <ContainerItem className="product_list_container">
                        <ProductList
                          productListData={value}
                          key={"ProductListItem_" + index}
                        />
                      </ContainerItem>
                    </SectionItem>
                  ) : (
                    <SectionItem key={"product_list_ads_" + index}>
                      <ContainerItem isFull={true}>
                        <MiddleAds
                          key={"MiddleAdsItem_" + index}
                          adsArray={listDate.ads}
                        />
                      </ContainerItem>
                    </SectionItem>
                  );
                })}
                <SectionItem
                  style={{
                    display: "none",
                  }}
                >
                  <ContainerItem>
                    <MoreButton>
                      <button className="more_button" onClick={addMoreList}>
                        看更多
                      </button>
                    </MoreButton>
                  </ContainerItem>
                </SectionItem>
              </div>
            </CSSTransition>
          );
        })}
      </div>
      <SectionItem>
        <ContainerItem isFull={true}>
          <Connect linkList={linkList} />
        </ContainerItem>
      </SectionItem>
      <SectionItem>
        <ContainerItem isFull={true}>
          <Footer />
        </ContainerItem>
      </SectionItem>
      <div className="float_bottom_ads">
        <div
          className="float_bottom_ads_close"
          onClick={(e) => {
            e.target.parentNode.remove();
          }}
        ></div>
        <div className="float_bottom_ads_container">
          <div className="float_bottom_ads_container_img">
            <img
              className="header_bar_logo_img"
              src={jingyinisland}
              alt="精淫岛"
            />
          </div>
          <div className="float_bottom_ads_container_p">
            将精淫岛导航新增至主画面避免您迷路了！ <br></br>{" "}
            {window.location.href}
          </div>
        </div>
      </div>
    </RootBody>
  );
}

export default App;
