// Import Swiper React components
import SwiperCore, { Navigation, Scrollbar, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

import React from "react";

import styled from "@emotion/styled";

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';

SwiperCore.use([Navigation, Scrollbar, A11y]);

const SwiperItem = styled(Swiper)`
  .swiper-button{
    &-prev{
      display: none;
    }
    &-next{
      width: auto;
      height: 100%;
      top: 0;
      bottom: 0;
      right: 0;
      margin: 0 -17px 0 0;
      background-color: #888888aa;
      color: #fff;
      &::after{
        content: '〉';
      }
    }
  }
`;

const SliderDiv = styled.div`
  position: relative;
  font-size: 1vw;
  .slider_item{
    &_img{
      width: 100%;
      vertical-align: middle;
    }
    &_text{
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 2px 0;
      text-align: center;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.76);
      font-size: 3em;
    }
  }
  @media (min-width: 750px){
    font-size: 6px;
  }
`;

const MiddleAds = ({adsArray}) =>{
  return (
    <SwiperItem
      spaceBetween={10}
      slidesPerView={2.1}
      freeMode={true}
      loop={true}
      navigation
      // onSwiper={(swiper) => console.log(swiper)}
      // onSlideChange={() => console.log('slide change')}
    > 
      {adsArray.map((value,index)=>{
        return (
          <SwiperSlide
            key={'ads_SwiperSlide_' + index}
          >
            <SliderDiv>
              <a 
                href={value.url}
                target="_blank"
                rel="noreferrer noopener"
              >
                <img className="slider_item_img" src={value.image} alt={value.text}/>
                <p className="slider_item_text">{value.text}</p>
              </a>
            </SliderDiv>
          </SwiperSlide>
        )
      })}
    </SwiperItem>
  )
}

export default MiddleAds;