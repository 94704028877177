import React, { useRef } from "react";
import styled from "@emotion/styled";

import popAd1 from "../assets/images/popAd1.gif";
import popAd2 from "../assets/images/popAd2.gif";
import popAd3 from "../assets/images/popAd3.gif";
import popAd4 from "../assets/images/popAd4.gif";
import popAd5 from "../assets/images/popAd5.gif";
import popAd6 from "../assets/images/popAd6.gif";
import popAd7 from "../assets/images/popAd7.gif";
import popAd8 from "../assets/images/popAd8.gif";
// import popAd9 from "../assets/images/popAd9.jpg";
// import popAd10 from "../assets/images/popAd10.gif";

// import popUpAds from '../assets/images/product/popAds.gif';

const PopUpItem = styled.div`
  z-index: 20;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000aa;
  .container {
    position: relative;
    top: 0;
    width: 95%;
    max-width: 400px;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;
    opacity: 1;
    transition: 1s;

    &_header {
      flex-shrink: 0;
      padding: 10px;
      background-image: linear-gradient( to bottom, rgba(255, 217, 0, 1), rgba(255, 226, 0, 1), #fef100 );
      &_card {
        &_header{
          &_title{
            text-align: center;
            font-size: 20px;
          }
        }
        &_body{
          padding-top: 10px;
          &_list{
            &_item{
              line-height: 26px;
            }
          }
        }
      }
    }
    &_body {
      flex-grow: 1;
      padding: 5px 0;
      overflow: auto;
      &_item {
        display: block;
        box-sizing: border-box;
        width: 100%;
        padding: 5px 10px;
        &_img {
          border-radius: 5px;
          width: 100%;
          vertical-align: middle;
        }
      }
    }
    &_footer {
      flex-shrink: 0;
      cursor: pointer;
      letter-spacing: 2px;
      font-size: 12px;
      background-image: linear-gradient( to top, rgba(255, 217, 0, 1), rgba(255, 226, 0, 1), #fef100 );
      padding: 15px 0;
      text-align: center;
    }
  }
`;

const PopUpAds = () =>{
const popItem = useRef(null);

const adsList = [
  {
    title: "密愛閣",
    url: "https://blid2.com/nZoRt/dl",
    img: popAd8,
  },
  // {
  //   title: "Lutube视频",
  //   url: "https://bliacg.com/lutu1/dl",
  //   img: popAd6,
  // },
  {
    title: "深海禁慾",
    url: "https://bliacg.com/lsg",
    img: popAd1,
  },
  {
    title: "H365廣告",
    url: "https://blid2.com/HAW-9d95d2w11fw/dl",
    img: popAd4,
  },
  {
    title: "蜜桃會所",
    url: "https://blid2.com/1z-sd5fwef2asdwekg/dl",
    img: popAd5,
  },
  // {
  //   title: "魅團",
  //   url: "https://blid2.com/metou-asdji23mfc823/dl",
  //   img: popAd3,
  // },
  // {
  //   title: "墮落機甲",
  //   url: "https://blid2.com/ero-d4sd8we2sdf/dl",
  //   img: popAd7,
  // },
  // {
  //   title: "非凡娛樂",
  //   url: "https://f324.vip/",
  //   img: popAd2,
  // },
];

  return (
    <PopUpItem 
      ref={popItem}
      onClick={
        ()=>{
          popItem.current.remove();
        }
      }
    >
      <div 
        className="container"
        onClick={(e)=>{
          e.stopPropagation();
        }}
      >
        <div className="container_header">
          <div className="container_header_card">
            <div className="container_header_card_header">
              <p className="container_header_card_header_title">
                收藏本站，尽享快乐
              </p>
            </div>
            <div className="container_header_card_body">
              <ol
                className="container_header_card_body_list"
              >
                <li className="container_header_card_body_list_item">
                  1、永久域名：
                  <a href="https://jingyindao.com/" rel="noreferrer" target="_blank">https://jingyindao.com/</a>
                </li>
                <li className="container_header_card_body_list_item">
                  2、商务合作对接：
                  <a href="mailto:jingyindao@gmail.com" rel="noreferrer" target="_blank">jingyindao@gmail.com</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div className="container_body">
          {adsList.map((data) => {
            return (
              <a
                className="container_body_item"
                href={data.url}
                rel="noreferrer"
                target="_blank"
                key={data.title}
              >
                <img
                  className="container_body_item_img"
                  src={data.img}
                  alt={data.title}
                  title={data.title}
                />
              </a>
            );
          })}
        </div>
        <div 
          className="container_footer"
          onClick={
            ()=>{
              popItem.current.remove();
            }
          }
        >关闭视窗</div>
      </div>
    </PopUpItem>
  )
}

export default PopUpAds;