const linkList = [
  {
    title: "B次元",
    url: "https://anime.bliav.com/?utm_source=jyd",
  },
  {
    title: "妹塔AV",
    url: "https://meitaav.com/?utm_source=jyd",
  },
  {
    title: '绅士会所',
    href: 'https://www.sshs.pw'
  },
  {
    title: "H365",
    url: "https://marketing.h365.site/bliporn/",
  },
  {
    title: "推剧Tuiju",
    url: "https://tuiju.tv/?utm_source=jyd",
  },
];


export {
  linkList
};
