//

import heightLightBarItemIcon1 from "./assets/images/product/app/heightLightBarItemIcon/icon1.png";
import heightLightBarItemIcon2 from "./assets/images/product/app/heightLightBarItemIcon/icon2.png";
import heightLightBarItemIcon3 from "./assets/images/product/app/heightLightBarItemIcon/icon3.png";
import heightLightBarItemIcon4 from "./assets/images/product/app/heightLightBarItemIcon/icon4.png";

//

import ad_slider1 from "./assets/images/product/app/sliderImg/slider1.gif";
import ad_slider2 from "./assets/images/product/app/sliderImg/slider2.gif";
import ad_slider3 from "./assets/images/product/app/sliderImg/slider3.gif";
import ad_slider4 from "./assets/images/product/app/sliderImg/slider4.gif";
import ad_slider5 from "./assets/images/product/app/sliderImg/slider5.gif";
// import ad_slider6 from "./assets/images/product/app/sliderImg/slider6.gif";
// import ad_slider7 from "./assets/images/product/app/sliderImg/slider7.gif";
// import ad_slider8 from "./assets/images/product/app/sliderImg/slider8.gif";
// import ad_slider9 from "./assets/images/product/app/sliderImg/slider9.gif";
// import ad_slider10 from "./assets/images/product/app/sliderImg/slider10.gif";

//

import ad_S1 from "./assets/images/product/app/midAds/ad_S1.png";
import ad_S2 from "./assets/images/product/app/midAds/ad_S2.png";

// productListImage

import product_bliav from "./assets/images/product/bliav.png";
import product_H365 from "./assets/images/product/H365.png";
import product_speedcat from "./assets/images/product/快猫.png";
import product_Mimosa from "./assets/images/product/含羞草.png";
import product_Mimacomic from "./assets/images/product/迷妹漫画.png";
import product_mado from "./assets/images/product/麻豆.png";
import product_gidon from "./assets/images/product/精东.png";
import product_tuiju from "./assets/images/product/app/listProduct/tuiju.png";

const heightLightBarItemArray = [
  {
    title: "B次元",
    image: heightLightBarItemIcon1,
    url: "https://bliav.com/downzai/",
  },
  {
    title: "泡泡直播",
    image: heightLightBarItemIcon2,
    url: "https://bliacg.com/bb/jy",
  },
  {
    title: "Lutube视频",
    image: heightLightBarItemIcon3,
    url: "https://bliacg.com/lutu2/icon",
  },
  {
    title: "雛鳥短視頻",
    image: heightLightBarItemIcon4,
    url: "https://cn08.me/?code=&channel=hh158640",
  },
];

const sliderPageArr = [
  {
    title: "Lutube视频",
    url: "https://bliacg.com/lutu2/jyd_hf",
    image: ad_slider1,
  },
  {
    title: "兵器少女",
    url: "https://l.tapdb.net/nMWyV5OP",
    image: ad_slider5,
  },
  {
    title: "泡泡直播",
    url: "https://bliacg.com/bb/jy",
    image: ad_slider3,
  },
  {
    title: "春香閣",
    url: "https://bliacg.com/yp",
    image: ad_slider2,
  },
  {
    title: "無限可能性的少女",
    url: "https://blid2.com/mgcm-sadjo28h3k1uhffased/dl",
    image: ad_slider4,
  },
];


const adsArray = [
  {
    text: "艳游记",
    image: ad_S1,
    url: "https://l.tapdb.net/T4sChGmK",
  },
  {
    text: "催眠异世界",
    image: ad_S2,
    url: "https://l.tapdb.net/prkhpWB3",
  },
  {
    text: "艳游记",
    image: ad_S1,
    url: "https://l.tapdb.net/T4sChGmK",
  },
  {
    text: "催眠异世界",
    image: ad_S2,
    url: "https://l.tapdb.net/prkhpWB3",
  },
];

const productListData = [
  {
    title: "推剧Tuiju",
    image: product_tuiju,
    size: "523.2m",
    version: "2.1.5",
    downloadTime: "8547855",
    url: "https://tuiju.tv/?utm_source=jyd",
  },
  {
    title: "Lutube视频",
    image: heightLightBarItemIcon3,
    size: "888.88m",
    version: "1.1.5",
    downloadTime: "8547855",
    url: "https://bliacg.com/lutu2/icon",
  },
  {
    title: "B次元",
    image: product_bliav,
    size: "2047.82m",
    version: "3.1.5",
    downloadTime: "12345",
    url: "https://anime.bliav.com/",
  },
  {
    title: "H365游戏",
    image: product_H365,
    size: "357m",
    version: "3.1.5",
    downloadTime: "152660",
    url: "https://marketing.h365.site/bliporn/",
  },
  {
    title: "快猫",
    image: product_speedcat,
    size: "2047.82m",
    version: "2.2.0",
    downloadTime: "188956",
    url: "https://anime.bliav.com/",
  },
  {
    title: "含羞草",
    image: product_Mimosa,
    size: "2047.82m",
    version: "2.2.0",
    downloadTime: "5800",
    url: "https://bliav.com/downzai/",
  },
  {
    title: "谜妹漫画",
    image: product_Mimacomic,
    size: "2047.82m",
    version: "2.2.0",
    downloadTime: "1411145",
    url: "https://17mimei.club/rwwd0",
  },
  {
    title: "麻豆",
    image: product_mado,
    size: "2047.82m",
    version: "2.2.0",
    downloadTime: "1411145",
    url: "https://bliav.com/downzai/",
  },
  {
    title: "精东",
    image: product_gidon,
    size: "2047.82m",
    version: "2.2.0",
    downloadTime: "188956",
    url: "https://bliav.com/downzai/",
  },
];

let data = {
  heightLightBarItemArray,
  sliderPageArr,
  productListData,
  adsArray,
};

export default data;
