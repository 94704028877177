//

import heightLightBarItemIcon1 from "./assets/images/product/stream/heightLightBarItemIcon/icon1.png";
import heightLightBarItemIcon2 from "./assets/images/product/stream/heightLightBarItemIcon/icon2.png";
import heightLightBarItemIcon3 from "./assets/images/product/stream/heightLightBarItemIcon/icon3.png";
import heightLightBarItemIcon4 from "./assets/images/product/stream/heightLightBarItemIcon/icon4.png";

//

import ad_slider1 from "./assets/images/product/stream/sliderImg/slider1.gif";
import ad_slider2 from "./assets/images/product/stream/sliderImg/slider2.gif";
import ad_slider3 from "./assets/images/product/stream/sliderImg/slider3.gif";
import ad_slider4 from "./assets/images/product/stream/sliderImg/slider4.gif";
import ad_slider5 from "./assets/images/product/stream/sliderImg/slider5.gif";
// import ad_slider6 from "./assets/images/product/stream/sliderImg/slider6.gif";
// import ad_slider7 from "./assets/images/product/stream/sliderImg/slider7.gif";
// import ad_slider8 from "./assets/images/product/stream/sliderImg/slider8.gif";
// import ad_slider9 from "./assets/images/product/stream/sliderImg/slider9.gif";
// import ad_slider10 from "./assets/images/product/stream/sliderImg/slider10.gif";

//

import ad_S1 from "./assets/images/product/stream/midAds/ad_S1.png";
import ad_S2 from "./assets/images/product/stream/midAds/ad_S2.png";

// productListImage

import product_LittleSexy from "./assets/images/product/小性感.png";
import product_LittleCat from "./assets/images/product/小喵宠.png";
import product_Lust from "./assets/images/product/色趣.png";
import product_MosquitoIncenseClub from "./assets/images/product/蚊香社.png";
import product_SheShow from "./assets/images/product/她秀.png";

// import product_woman1 from "./assets/images/product/stream/listProduct/woman1.gif";
// import product_woman2 from "./assets/images/product/stream/listProduct/woman2.gif";
// import product_woman3 from "./assets/images/product/stream/listProduct/woman3.gif";

const heightLightBarItemArray = [
  {
    title: "B次元",
    image: heightLightBarItemIcon1,
    url: "https://bliav.com/downzai/",
  },
  {
    title: "泡泡直播",
    image: heightLightBarItemIcon2,
    url: "https://bliacg.com/bb/jy",
  },
  {
    title: "Lutube视频",
    image: heightLightBarItemIcon3,
    url: "https://bliacg.com/lutu2/icon",
  },
  {
    title: "雛鳥短視頻",
    image: heightLightBarItemIcon4,
    url: "https://cn08.me/?code=&channel=hh158640",
  },
];

const sliderPageArr = [
  {
    title: "Lutube视频",
    url: "https://bliacg.com/lutu2/jyd_hf",
    image: ad_slider1,
  },
  {
    title: "兵器少女",
    url: "https://l.tapdb.net/nMWyV5OP",
    image: ad_slider5,
  },
  {
    title: "泡泡直播",
    url: "https://bliacg.com/bb/jy",
    image: ad_slider3,
  },
  {
    title: "春香閣",
    url: "https://bliacg.com/yp",
    image: ad_slider2,
  },
  {
    title: "無限可能性的少女",
    url: "https://blid2.com/mgcm-sadjo28h3k1uhffased/dl",
    image: ad_slider4,
  },
];

const adsArray = [
  {
    text: "艳游记",
    image: ad_S1,
    url: "https://l.tapdb.net/T4sChGmK",
  },
  {
    text: "催眠异世界",
    image: ad_S2,
    url: "https://l.tapdb.net/prkhpWB3",
  },
  {
    text: "艳游记",
    image: ad_S1,
    url: "https://l.tapdb.net/T4sChGmK",
  },
  {
    text: "催眠异世界",
    image: ad_S2,
    url: "https://l.tapdb.net/prkhpWB3",
  },
];

const productListData = [
  // {
  //   title: "站长推荐直播APP",
  //   image: product_woman1,
  //   size: "45.82m",
  //   version: "1.1.6",
  //   downloadTime: "5891254",
  //   url: "https://bliacg.com/JI/icon2",
  // },
  // {
  //   title: "BB直播",
  //   image: product_woman3,
  //   size: "67.82m",
  //   version: "1.0.0",
  //   downloadTime: "984512",
  //   url: "https://bliacg.com/69m/icon2",
  // },
  // {
  //   title: "百合直播",
  //   image: product_woman2,
  //   size: "22.82m",
  //   version: "1.0.0",
  //   downloadTime: "984512",
  //   url: "https://bliacg.com/liv/icon",
  // },
  {
    title: "小性感",
    image: product_LittleSexy,
    size: "2047.82m",
    version: "3.1.5",
    downloadTime: "12345",
    url: "https://bliav.com/downzai/",
  },
  {
    title: "小喵宠",
    image: product_LittleCat,
    size: "357m",
    version: "3.1.5",
    downloadTime: "152660",
    url: "https://bliav.com/downzai/",
  },
  {
    title: "色趣",
    image: product_Lust,
    size: "2047.82m",
    version: "2.2.0",
    downloadTime: "188956",
    url: "https://bliav.com/downzai/",
  },
  {
    title: "蚊香社",
    image: product_MosquitoIncenseClub,
    size: "2047.82m",
    version: "2.2.0",
    downloadTime: "5800",
    url: "https://bliav.com/downzai/",
  },
  {
    title: "她秀",
    image: product_SheShow,
    size: "2047.82m",
    version: "2.2.0",
    downloadTime: "1411145",
    url: "https://bliav.com/downzai/",
  }
];

let data = {
  heightLightBarItemArray,
  sliderPageArr,
  productListData,
  adsArray,
};

export default data;
