import React from "react";
import styled from "@emotion/styled";

const HeightLightBar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
`;

const HeightLightBarItem = styled.div`
width: 20%;
.heightlight_bar_item{
    text-decoration: none;
    &_imgbox{
      overflow: hidden;
      border-radius: 15px;
      &_img{
        width: 100%;
        vertical-align: middle;
      }
    }
    &_textbox{
      color: #000;
      margin-top: 5px;
      text-align: center;
      font-weight: 500;
    }
  }
`;

const HeightLight = ({heightLightBarItemArray}) => {
  return (
    <HeightLightBar>
      {heightLightBarItemArray.map((value, index) => {
        return (
          <HeightLightBarItem
            key={'HeightLightBarItem_' + index}
          >
            <a 
              className="heightlight_bar_item"
              href={value.url} 
              target="_blank"
              rel="noreferrer noopener"
            >
              <div className="heightlight_bar_item_imgbox">
                <img className="heightlight_bar_item_imgbox_img" src={ value.image } alt={value.title}/>
              </div>
              <div className="heightlight_bar_item_textbox">
                {value.title}
              </div>
            </a>
          </HeightLightBarItem>
        )
      })}
    </HeightLightBar>
  )
}

export default HeightLight;