import React from 'react';
import ReactDOM from 'react-dom';
import './reset.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

function checkUserDevice() {
  var mobileDevices = ['Android', 'webOS', 'iPhone', 'iPad', 'iPod', 'BlackBerry', 'Windows Phone']
  var isMobileDevice=false
  for(var i=0;i<mobileDevices.length;i++){
      if(navigator.userAgent.match(mobileDevices[i])){
          isMobileDevice=true
      }
  }
  return isMobileDevice
}

window.addEventListener('contextmenu', (e)=>{
  if(!checkUserDevice()) {
    e.preventDefault();
    alert('喜欢这些福利吗? 请按Ctrl+D添加到收藏夹！');
  }
})