//

import heightLightBarItemIcon1 from "./assets/images/product/animat/heightLightBarItemIcon/icon1.png";
import heightLightBarItemIcon2 from "./assets/images/product/animat/heightLightBarItemIcon/icon2.png";
import heightLightBarItemIcon3 from "./assets/images/product/animat/heightLightBarItemIcon/icon3.png";
import heightLightBarItemIcon4 from "./assets/images/product/animat/heightLightBarItemIcon/icon4.png";

//

import ad_slider1 from "./assets/images/product/animat/sliderImg/slider1.gif";
import ad_slider2 from "./assets/images/product/animat/sliderImg/slider2.gif";
import ad_slider3 from "./assets/images/product/animat/sliderImg/slider3.gif";
import ad_slider4 from "./assets/images/product/animat/sliderImg/slider4.gif";
import ad_slider5 from "./assets/images/product/animat/sliderImg/slider5.gif";
// import ad_slider6 from "./assets/images/product/animat/sliderImg/slider6.gif";
// import ad_slider7 from "./assets/images/product/animat/sliderImg/slider7.gif";
// import ad_slider8 from "./assets/images/product/animat/sliderImg/slider8.gif";
// import ad_slider9 from "./assets/images/product/animat/sliderImg/slider9.gif";
// import ad_slider10 from "./assets/images/product/animat/sliderImg/slider10.gif";

//

import ad_S1 from "./assets/images/product/animat/midAds/ad_S1.png";
import ad_S2 from "./assets/images/product/animat/midAds/ad_S2.png";

// productListImage

import product_bliav from "./assets/images/product/bliav.png";
import product_DirtyComics from "./assets/images/product/污污漫画.webp";
import product_ForbiddenParadise from "./assets/images/product/禁漫天堂.jpg";
import product_nHentai from "./assets/images/product/nHentai.jpg";
import product_EHentai from "./assets/images/product/E-Hentai.jpg";
import product_GentlemanComics from "./assets/images/product/绅士漫画.png";
import product_MANGAGO from "./assets/images/product/MANGAGO.png";
import product_toptoon from "./assets/images/product/頂通漫畫.png";
import product_sexcomicweb from "./assets/images/product/色漫網.jpg";

const heightLightBarItemArray = [
  {
    title: "B次元",
    image: heightLightBarItemIcon1,
    url: "https://bliav.com/downzai/",
  },
  {
    title: "泡泡直播",
    image: heightLightBarItemIcon2,
    url: "https://bliacg.com/bb/jy",
  },
  {
    title: "Lutube视频",
    image: heightLightBarItemIcon3,
    url: "https://bliacg.com/lutu2/icon",
  },
  {
    title: "雛鳥短視頻",
    image: heightLightBarItemIcon4,
    url: "https://cn08.me/?code=&channel=hh158640",
  },
];

const sliderPageArr = [
  {
    title: "Lutube视频",
    url: "https://bliacg.com/lutu2/jyd_hf",
    image: ad_slider1,
  },
  {
    title: "兵器少女",
    url: "https://l.tapdb.net/nMWyV5OP",
    image: ad_slider5,
  },
  {
    title: "泡泡直播",
    url: "https://bliacg.com/bb/jy",
    image: ad_slider3,
  },
  {
    title: "春香閣",
    url: "https://bliacg.com/yp",
    image: ad_slider2,
  },
  {
    title: "無限可能性的少女",
    url: "https://blid2.com/mgcm-sadjo28h3k1uhffased/dl",
    image: ad_slider4,
  },
];

const adsArray = [
  {
    text: "艳游记",
    image: ad_S1,
    url: "https://l.tapdb.net/T4sChGmK",
  },
  {
    text: "催眠异世界",
    image: ad_S2,
    url: "https://l.tapdb.net/prkhpWB3",
  },
  {
    text: "艳游记",
    image: ad_S1,
    url: "https://l.tapdb.net/T4sChGmK",
  },
  {
    text: "催眠异世界",
    image: ad_S2,
    url: "https://l.tapdb.net/prkhpWB3",
  },
];

const productListData = [
  {
    title: "B次元动漫",
    image: product_bliav,
    size: "2047.82m",
    version: "3.1.5",
    downloadTime: "12345",
    url: "https://anime.bliav.com/",
  },
  {
    title: "污污漫画",
    image: product_DirtyComics,
    size: "357m",
    version: "3.1.5",
    downloadTime: "152660",
    url: "https://comicbox.xyz/",
  },
  {
    title: "禁漫天堂",
    image: product_ForbiddenParadise,
    size: "2047.82m",
    version: "2.2.0",
    downloadTime: "188956",
    url: "https://18comic.org/",
  },
  {
    title: "nHentai",
    image: product_nHentai,
    size: "2047.82m",
    version: "2.2.0",
    downloadTime: "5800",
    url: "https://nhentai.net/",
  },
  {
    title: "E-Hentai",
    image: product_EHentai,
    size: "2047.82m",
    version: "2.2.0",
    downloadTime: "1411145",
    url: "https://e-hentai.org/",
  },
  {
    title: "紳士漫畫",
    image: product_GentlemanComics,
    size: "2047.82m",
    version: "2.2.0",
    downloadTime: "1411145",
    url: "http://www.wnacg.org/",
  },
  {
    title: "Mangago",
    image: product_MANGAGO,
    size: "2047.82m",
    version: "2.2.0",
    downloadTime: "188956",
    url: "http://www.mangago.me/",
  },
  {
    title: "頂通漫畫",
    image: product_toptoon,
    size: "2047.82m",
    version: "2.2.0",
    downloadTime: "5800",
    url: "https://toptoon.casa/#2",
  },
  {
    title: "色漫網",
    image: product_sexcomicweb,
    size: "2047.82m",
    version: "2.2.0",
    downloadTime: "1411145",
    url: "https://www.cartoon18.com/",
  },
];

let data = {
  heightLightBarItemArray,
  sliderPageArr,
  productListData,
  adsArray,
};

export default data;
