// Import Swiper React components
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';


import styled from "@emotion/styled";

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

const SliderItem = styled(Swiper)`
  .swiper-pagination-bullet{
    width: 30px;
    height: 10px;
    border-radius: 0;
  }
  .swiper-pagination-bullet-active{
    background-color: #f24c7c;
  }
`;

const SliderSection = ({sliderPageArr})=>{
  return (
    <SliderItem
      spaceBetween={0}
      slidesPerView={1}
      pagination={{ clickable: true }}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false
      }}
      autoHeight={true}
      loop={true}
      // onSwiper={(swiper) => console.log(swiper)}
      // onSlideChange={() => console.log('slide change')}
    >
      {sliderPageArr.map((value, index) => {
        return (
          <SwiperSlide
            key={'SwiperSlide' + index}
          >
            <a 
              href={value.url}
              target="_blank"
              rel="noreferrer noopener"
            >
              <img style={{width: '100%'}} src={value.image} alt={value.title}/>
            </a>
          </SwiperSlide>
        )
      })}
    </SliderItem>
  )
}

export default SliderSection;