import React from "react";
import styled from "@emotion/styled";

const FooterItem = styled.footer`
  padding: 15px;
  border-top: 1px solid #c3c3c3;
  text-align: center;
  color: #666666;
  .footer_url{
    color: #fc5c53;
    text-decoration: none;
  }
`;

const Footer = ()=>{
  return(
    <FooterItem>
        © 2021 精淫岛 all right reserved｜<a className="footer_url" href="mailto:jingyindao@gmail.com">广告联系</a>
    </FooterItem>
  )
}

export default Footer;